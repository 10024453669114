<mat-form-field appearance="fill" class="autocomplete-multi-select">
  <mat-label>{{ placeholder }}</mat-label>
  <input #inputElement
         type="text"
         matInput
         [placeholder]="placeholder"
         [formControl]="nameFormControl"
         [matAutocomplete]="auto"
  />
  <mat-autocomplete #auto='matAutocomplete'>
    <mat-option
      *ngFor="let option of filteredOptions$ | async"
      (mousedown)="onMouseDown()"
      (click)="onToggleSelection(option); inputElement.blur(); inputElement.focus()"
    >
      <mat-checkbox [checked]="isSelected(option)">{{ option.name }}</mat-checkbox>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

