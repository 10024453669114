import { Pipe, PipeTransform } from '@angular/core';
import { ClinicLocation } from '../models/clinic-location.model';

@Pipe({
  name: 'onlyReceiptHandlingLocations',
})
export class OnlyReceiptHandlingLocationsPipe implements PipeTransform {
  transform(locations: ClinicLocation[]): ClinicLocation[] {
    return locations.filter((cl) => !!cl.receiptPrefixId);
  }
}
