import { Pipe, PipeTransform } from '@angular/core';
import { Appointment } from '../models/appointment.model';
import { PatientOfferedActionStatus } from '../models/patient-offered-action.model';

@Pipe({
  name: 'hasAppointmentUnpaidActions',
})
export class HasAppointmentUnpaidActionsPipe implements PipeTransform {
  transform(appointment: Appointment): boolean {
    return appointment?.patientOfferedActions?.some(
      (poa) =>
        poa.status === PatientOfferedActionStatus.open &&
        poa?.patientAction?.price > 0,
    );
  }
}
