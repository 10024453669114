import { Pipe, PipeTransform } from '@angular/core';
import { DoctorAnalyticsOverview } from '../../models/analytics.model';

@Pipe({
  name: 'sumDoctorCosts',
})
export class SumDoctorCostsPipe implements PipeTransform {
  transform(
    doctorAnalyticsOverviews: DoctorAnalyticsOverview[],
    calcBonuses?: undefined | 'START' | 'FINISH',
  ): number {
    return doctorAnalyticsOverviews
      .map((a) =>
        calcBonuses
          ? 'START' === calcBonuses
            ? a.bonusStart
            : a.bonusFinish
          : a.totalPrice,
      )
      .reduce((totalPrice, price) => totalPrice + price, 0);
  }
}
