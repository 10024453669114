<div class="comment-card"
     [style]="'border-left: solid 3px ' + comment.authorUserInfo.color + ';'">
  <div class='title'>
    <div>{{ comment.authorUserInfo.name }} {{ comment.authorUserInfo.lastName }}</div>
    <div>{{ comment.modifiedDateTime | date: 'dd.MM.yyyy'}}</div>
    <button *ngIf='editMode'
      mat-icon-button
      (click)='onCommentClicked()'
    >
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <div class='body' [innerHTML]="comment.content | nlToBr"></div>
</div>
