import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Doctor } from '../../models/doctor.model';
import { Appointment } from '../../models/appointment.model';
import { Patient } from '../../models/patient.model';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class DoctorService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/doctor';
  }

  public get(doctorId: number): Observable<Doctor> {
    return this.http.get<Doctor>(this.apiUrl + `/${doctorId}`);
  }

  public add(doctor: User): Observable<number> {
    return this.http.post<number>(this.apiUrl, doctor);
  }

  public update(doctor: User): Observable<Doctor> {
    return this.http.patch<Doctor>(this.apiUrl, doctor);
  }

  public delete(doctorId: number): Observable<any> {
    return this.http.delete<any>(this.apiUrl + `/${doctorId}`);
  }

  public getByClinicId(
    clinicId: number,
    includeDeleted: boolean = false,
  ): Observable<Doctor[]> {
    return this.http.get<Doctor[]>(
      this.apiUrl + `/by-clinic-id/${clinicId}/${includeDeleted}`,
    );
  }
  public getDoctorAppointments(
    doctorId: number,
    page: number,
    perPage: number,
    from: Date,
    to: Date,
  ): Observable<Appointment[]> {
    let queryParams = '?';
    queryParams += 'page=' + page;
    queryParams += '&perPage=' + perPage;
    if (from) {
      queryParams += '&from=' + from.toISOString();
    }
    if (to) {
      queryParams += '&to=' + to?.toISOString();
    }

    return this.http.get<Appointment[]>(
      this.apiUrl + `/${doctorId}/appointments${queryParams}`,
    );
  }

  public getDoctorPatients(doctorId: number): Observable<Patient[]> {
    return this.http.get<Patient[]>(this.apiUrl + `/${doctorId}/patients`);
  }
}
