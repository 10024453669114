import { Component, Inject, OnDestroy, ViewChild } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  PatientOfferedAction,
  PatientOfferedActionStatus,
} from '../../models/patient-offered-action.model';
import { teethEnumTranslation } from '../../utils/teeth.utils';
import { DropdownItem } from '../dropdown-search/dropdown-search.component';
import { ToastService } from '../../services/other-services/toast.service';
import { FormBuilder, UntypedFormControl } from '@angular/forms';
import { PatientAction } from '../../models/patient-action.model';
import { SelectPatientActionDialogComponent } from '../../shared-modules/patient-pages/patient-info-pages/patient-therapy-page/select-patient-action-dialog/select-patient-action-dialog.component';
import {
  catchError,
  filter,
  map,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { PatientOfferedActionService } from '../../services/api-services/patient-offered-action.service';
import { StoreService } from '../../services/api-services/store.service';
import { AutocompleteMultiSelectComponent } from '../../standalone-components/dropdown-multi-select/autocomplete-multi-select.component';

@Component({
  selector: 'app-add-appointment-action-dialog',
  templateUrl: './add-appointment-action-dialog.component.html',
  styleUrls: ['./add-appointment-action-dialog.component.scss'],
})
export class AddAppointmentActionDialogComponent implements OnDestroy {
  @ViewChild('multiSelectAutocomplete')
  multiSelectAutocompleteComponent: AutocompleteMultiSelectComponent;

  poasToAdd$: BehaviorSubject<PatientOfferedAction[]> = new BehaviorSubject([]);
  form = this.fb.group({});
  patientActions: PatientAction[];
  patientId: number;
  newPoasToAdd$ = new BehaviorSubject<number[]>(undefined);
  loadPatientOfferedActions$ = new Subject<void>();
  patientOfferedActions$ = this.loadPatientOfferedActions$.pipe(
    startWith(''),
    switchMap(() =>
      this.patientOfferedActionService
        .getByPatientId(this.patientId)
        .pipe(catchError(() => of([]))),
    ),
    map((poas) =>
      poas.filter(
        (poa) =>
          poa?.status === PatientOfferedActionStatus.open &&
          !poa?.appointmentId,
      ),
    ),
    withLatestFrom(this.newPoasToAdd$),
    map(([poas, newPoasToAdd]) => {
      if (newPoasToAdd) {
        this.newPoasToAdd$.next(undefined);
        const newPoas = poas?.filter((p) => newPoasToAdd?.includes(p.id));
        this.poasToAdd$.next([...this.poasToAdd$.value, ...newPoas]);
        newPoasToAdd.forEach((id) => {
          this.form.addControl(`${id}-comment`, new UntypedFormControl(''));
          this.form.addControl(`${id}-plane`, new UntypedFormControl(''));
        });
      }
      return poas;
    }),
    shareReplay(1),
  );
  allPoasDropdown$: Observable<DropdownItem[]> = combineLatest([
    this.poasToAdd$,
    this.patientOfferedActions$,
  ]).pipe(
    map(([poasToAdd, poas]) => {
      const filteredMappedItems = poas
        ?.filter((poa) => !poasToAdd?.some((a) => a?.id === poa?.id))
        .map((poa) => ({
          id: poa?.id,
          name: `${poa?.patientAction?.name} (${teethEnumTranslation(
            poa?.teeth,
          )})`,
        }));
      return (filteredMappedItems ? [...filteredMappedItems] : []).sort(
        (a, b) => a.name.localeCompare(b.name),
      );
    }),
  );
  private readonly destroy$ = new Subject<void>();

  constructor(
    public fb: FormBuilder,
    private dialog: MatDialog,
    private storeService: StoreService,
    private toastService: ToastService,
    private patientOfferedActionService: PatientOfferedActionService,
    private dialogRef: MatDialogRef<AddAppointmentActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      patientActions: PatientAction[];
      appointmentId: number;
      patientId: number;
    },
  ) {
    this.patientActions = data.patientActions;
    this.patientId = data.patientId;
    this.loadPatientOfferedActions$.next();
  }

  public add(): void {
    if (!this.poasToAdd$.value?.length) {
      this.toastService.warning('Izberi vsaj eno akcijo!');
      return;
    }
    const result = this.poasToAdd$.value.map((p) => ({
      ...p,
      appointmentId: this.data.appointmentId,
      appointmentComment: this.form.controls[`${p.id}-comment`].value,
      appointmentToothPlane: this.form.controls[`${p.id}-plane`].value,
    }));
    this.dialogRef.close(result);
  }

  addNewPatientOfferedAction(patientOfferedActions: PatientOfferedAction[]) {
    const selectedItems: DropdownItem[] =
      this.multiSelectAutocompleteComponent.selectedItems;
    this.multiSelectAutocompleteComponent.nameFormControl.reset();
    if (!selectedItems) {
      this.toastService.warning('Morate izabrati storitvu za dodati!');
      return;
    }
    this.multiSelectAutocompleteComponent.selectedItems = [];
    const poasToAdd = selectedItems.map((selected) =>
      patientOfferedActions.find((poa) => poa?.id === selected.id),
    );
    this.poasToAdd$.next([...this.poasToAdd$.value, ...poasToAdd]);
    poasToAdd.forEach((poa) => {
      this.form.addControl(`${poa.id}-comment`, new UntypedFormControl(''));
      this.form.addControl(`${poa.id}-plane`, new UntypedFormControl(''));
    });
  }

  createNewPoa() {
    const dialogRef = this.dialog.open(SelectPatientActionDialogComponent, {
      width: '40rem',
      autoFocus: false,
      disableClose: true,
      data: { patientActions: this.patientActions, patientId: this.patientId },
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        filter((a) => !!a),
        switchMap((patientOfferedActions: PatientOfferedAction[]) =>
          this.patientOfferedActionService.add(patientOfferedActions),
        ),
      )
      .subscribe((ids) => {
        this.newPoasToAdd$.next(ids);
        this.loadPatientOfferedActions$.next();
        this.storeService.dispatchRefreshTeethOverview();
      });
  }

  removePoa(poaId: number) {
    const poasToAdd = this.poasToAdd$.value.filter((poa) => poa?.id !== poaId);
    this.poasToAdd$.next(poasToAdd);
    this.form.removeControl(`${poaId}-comment`);
    this.form.removeControl(`${poaId}-plane`);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
