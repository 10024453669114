import { Pipe, PipeTransform } from '@angular/core';
import { PatientOfferedAction } from '../models/patient-offered-action.model';
import { SimplifiedActionForInvoice } from '../models/invoice.model';

@Pipe({
  name: 'formatActionsForInvoice',
})
export class FormatActionsForInvoicePipe implements PipeTransform {
  transform(
    poas: PatientOfferedAction[],
    expandedSafiIds: number[],
    priceMap: Map<number, number>,
  ): SimplifiedActionForInvoice[] {
    return [
      ...poas
        .reduce((accumulator, poa) => {
          if (accumulator.has(poa.patientActionId)) {
            accumulator.get(poa.patientActionId).amount =
              accumulator.get(poa.patientActionId).amount + 1;
            accumulator.get(poa.patientActionId).poas.push(poa);
          } else {
            accumulator.set(poa.patientActionId, {
              id: poa.patientActionId,
              name: poa.patientAction.name,
              price:
                priceMap?.get(poa?.patientActionId) ??
                poa?.patientAction?.price,
              amount: 1,
              poas: [poa],
              isExpanded: expandedSafiIds?.some(
                (id) => id === poa.patientActionId,
              ),
            });
          }
          return accumulator;
        }, new Map<number, SimplifiedActionForInvoice>())
        .values(),
    ];
  }
}
