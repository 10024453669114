<div class='main'>

  <div *ngIf='combinedReceiptsData$ | async as myDataSource'>
    <form [formGroup]='formGroup' class='filters'>
      <div class='filters__calendar'>
        <label>Datum</label>
        <input type='date' class='form-control' onkeydown='return false' formControlName='date' [max]='today'>
      </div>

      <div>
        <label>Adresa</label>
        <select id='clinicLocationInput' *ngIf='addresses$ | async as addresses'
                class='form-control' formControlName='address'>
          <option value=''>Izberi lokacijo...</option>
          <option *ngFor='let address of addresses | onlyReceiptHandlingLocations' [ngValue]='address'>
            {{ address.content }}
          </option>
        </select>
      </div>

      <div class='filters__button' *ngIf='!isLoading'>
        <div>
          <button class='btn btn-primary' (click)='resetFilters()'>Resetuj filtere</button>
        </div>
        <div *ngIf='shouldShowDepositButton(myDataSource.info)'>
          <button class='btn btn-primary' (click)='openDialog()'>
            Unesi izdatek
          </button>
        </div>
        <div *ngIf="shouldShowButton('lock', myDataSource.info)">
          <button class='btn btn-primary' (click)='cashRegisterLockedToggle(myDataSource, true)'>
            Zaključaj blagajnu
          </button>
        </div>
        <div *ngIf="shouldShowButton('unlock', myDataSource.info)">
          <button class='btn btn-primary' (click)='cashRegisterLockedToggle(myDataSource, false)'>
            Otključaj blagajnu
          </button>
        </div>
      </div>
    </form>

    <br />

    <div style='text-align: center; padding-top: 2.5rem' *ngIf='isLoading'>
      <div class='spinner-border text-primary spinner' role='status'>
        <span class='sr-only'></span>
      </div>
    </div>

    <ng-container *ngIf='!isLoading'>

        <div class='info'>
          <div class='info__amounts' *ngIf='myDataSource.info; else infoNotExists'>
            <p><strong>Začetno stanje: </strong>€ {{ myDataSource?.info?.startingAmount ?? 0 | number: '.2-2': 'sl' }}
            </p>
            <p><strong>Konačno stanje: </strong>
              {{ (myDataSource.info?.isLocked || myDataSource.info.finalAmount) ? ('€ ' + (myDataSource?.info?.finalAmount | number: '.2-2': 'sl')) : '-' }}
            </p>
          </div>

          <ng-template #infoNotExists>
            <div *ngIf='formGroup.get("address").value; else chooseAddressText' style='width: 100%'>
              <div class='info__register_not_locked'>
                <p>Blagajna od juče nije zaključana</p>
              </div>
            </div>

            <ng-template #chooseAddressText>
              <div class='info__register_not_locked'>
                <p>Izaberite adresu da otvorite blagajnu</p>
              </div>
            </ng-template>

          </ng-template>


          <div class='info__print' *ngIf='shouldShowPrintButton(myDataSource.receipts)'>
            <button (click)='createPdf()' class='btn btn-light' style='justify-content: end'>
              <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-printer'
                   viewBox='0 0 16 16'>
                <path d='M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z' />
                <path
                  d='M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z' />
              </svg>
            </button>
          </div>
        </div>

        <br />

        <ng-container *ngIf='myDataSource.receipts.length === 0; else table'>
          <br />
          Za ovaj dan nema ni jedan račun u blagajni
        </ng-container>

        <ng-template #table>

        <table mat-table [dataSource]="myDataSource.receipts" class="mat-elevation-z8">

            <ng-container matColumnDef='številka'>
              <th mat-header-cell *matHeaderCellDef>Številka</th>
              <td mat-cell class='table__cell' *matCellDef='let receipt; ' style='width: 18% !important;'
                  [ngClass]="{'bank-deposit': isBankDeposit(receipt)}">
                <a *ngIf="receipt.identificationNumber !== '-'; else noRouting"
                   [routerLink]="getRouteToNavigate(receipt)">{{receipt.identificationNumber}}</a>
                <ng-template #noRouting>{{receipt.identificationNumber}}</ng-template>
              </td>
              <td class='footer' *matFooterCellDef> Skupaj blaganiški dnevnik</td>
            </ng-container>

            <ng-container matColumnDef='patient'>
              <th mat-header-cell *matHeaderCellDef>Pacijent</th>
              <td mat-cell class='table__cell' *matCellDef='let receipt; '
                  [ngClass]="{'bank-deposit': isBankDeposit(receipt)}">
                {{receipt.patientName}}
              </td>
              <td class='footer' *matFooterCellDef></td>
            </ng-container>


            <ng-container matColumnDef='amount'>
              <th mat-header-cell *matHeaderCellDef>Prejemek</th>
              <td mat-cell class='table__cell' *matCellDef='let receipt; ' style='width: 15% !important;'
                  [ngClass]="{'bank-deposit': isBankDeposit(receipt)}">
                € {{ receipt.amount | number: '.2-2': 'sl' }}
              </td>
              <td class='footer' *matFooterCellDef>€ {{ myDataSource.receipts | receiptSum | number: '.2-2': 'sl'}}</td>
            </ng-container>

            <ng-container matColumnDef='creator'>
              <th mat-header-cell *matHeaderCellDef>Korisnik</th>
              <td mat-cell class='table__cell' *matCellDef='let receipt; '
                  [ngClass]="{'bank-deposit': isBankDeposit(receipt)}">
                {{receipt.author}}
              </td>
              <td class='footer' *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef='address'>
              <th mat-header-cell *matHeaderCellDef>Lokacija</th>
              <td mat-cell class='table__cell' *matCellDef='let receipt; '
                  [ngClass]="{'bank-deposit': isBankDeposit(receipt)}">
                {{receipt.address}}
              </td>
              <td class='footer' *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
            <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
            <tr mat-footer-row *matFooterRowDef='displayedColumns; sticky: true'></tr>
        </table>

        </ng-template>

    </ng-container>
  </div>
</div>
