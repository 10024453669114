import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Appointment } from '../../models/appointment.model';
import { Comment } from '../../models/comment.model';
import { addMinutes } from 'date-fns';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin, Observable, Subject } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { ToastService } from '../../services/other-services/toast.service';
import { StoreService } from '../../services/api-services/store.service';
import { PatientOfferedActionService } from '../../services/api-services/patient-offered-action.service';
import {
  PatientOfferedAction,
  PatientOfferedActionStatus,
  TeethEnum,
} from '../../models/patient-offered-action.model';
import { EditAppointmentActionDialogComponent } from '../edit-appointment-action-dialog/edit-appointment-action-dialog.component';
import { AddAppointmentActionDialogComponent } from '../add-appointment-action-dialog/add-appointment-action-dialog.component';
import { PatientActionService } from '../../services/api-services/patient-action.service';
import { LocalStorageService } from '../../services/api-services/local-storage.service';
import { CreateBillDialogComponent } from '../../shared-modules/patient-pages/patient-info-pages/create-bill-dialog/create-bill-dialog.component';
import { RECEIPT } from '../../utils/constants/pages.constants';
import { AdvanceReceipt, Receipt } from '../../models/receipt.model';
import { PaymentMethodService } from '../../services/api-services/payment-method.service';
import { ReceiptService } from '../../services/api-services/receipt.service';
import { RELEVANT_PAYMENT_METHOD_IDS } from '../../utils/constants/constants';
import { InvoiceService } from '../../services/api-services/invoice.service';
import { Invoice, InvoiceAction } from '../../models/invoice.model';
import { ClinicLocationService } from '../../services/api-services/clinic-location.service';
import { determineAppointmentActionsStatus } from '../../utils/color.utils';

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.scss'],
})
export class AppointmentCardComponent implements OnInit, OnDestroy {
  protected readonly determineAppointmentActionsStatus =
    determineAppointmentActionsStatus;

  @Output() public editClicked = new EventEmitter<number>();
  @Output() public addCommentClicked = new EventEmitter<number>();
  @Output() public editCommentClicked = new EventEmitter<Comment>();
  @Output() public refreshAppointments = new EventEmitter<void>();

  @Input() public appointment: Appointment;
  @Input() public index: number;

  readonly destroy$ = new Subject<void>();
  readonly patientActions$ = this.patientActionService
    .getByClinicId(this.storageService.getClinicId())
    .pipe(shareReplay(1));
  receiptAddresses$ = this.clinicLocationService.getClinicAddresses().pipe(
    map((a) => a.filter((cl) => !!cl.receiptPrefixId)),
    shareReplay(1),
  );
  paymentMethods$ = this.paymentMethodService.getAll().pipe(
    map((pm) => pm.filter((p) => RELEVANT_PAYMENT_METHOD_IDS.includes(p.id))),
    shareReplay(1),
  );
  patientActiveTherapyInvoice$: Observable<Invoice>;
  advanceReceipts$: Observable<AdvanceReceipt[]>;
  patientOfferedActions$: Observable<PatientOfferedAction[]>;
  public showGetMore: boolean;
  public endDateTime: Date;
  userHasOperatorTaxNumber: boolean;
  currentUserId: number;
  constructor(
    private dialog: MatDialog,
    private toastService: ToastService,
    private storeService: StoreService,
    private invoiceService: InvoiceService,
    private receiptService: ReceiptService,
    private storageService: LocalStorageService,
    private patientActionService: PatientActionService,
    private paymentMethodService: PaymentMethodService,
    private clinicLocationService: ClinicLocationService,
    private patientOfferedActionService: PatientOfferedActionService,
  ) {
    this.showGetMore = true;
    this.currentUserId = this.storageService.getUserId();
  }

  ngOnInit(): void {
    this.endDateTime = addMinutes(
      new Date(this.appointment.startDateTime),
      this.appointment.duration,
    );
    this.advanceReceipts$ = this.receiptService
      .getPatientAdvanceReceipts(this.appointment.patientId)
      .pipe(
        map((ar) => ar.filter((adv) => adv.remainingBalance > 0)),
        shareReplay(1),
      );

    this.patientOfferedActions$ = this.patientOfferedActionService
      .getByPatientId(this.appointment.patientId)
      .pipe(
        map((actions) =>
          actions.filter(
            (action) =>
              action.status === PatientOfferedActionStatus.open &&
              action?.patientAction?.price > 0,
          ),
        ),
        shareReplay(1),
      );

    this.patientActiveTherapyInvoice$ = this.invoiceService
      .getPatientActiveTherapyInvoice(this.appointment.patientId)
      .pipe(shareReplay(1));

    this.userHasOperatorTaxNumber =
      !!this.storageService.getUserOperatorNumber();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onEditClicked(): void {
    this.editClicked.emit(this.appointment.id);
  }

  public addComment(): void {
    this.addCommentClicked.emit(this.appointment.id);
  }

  public onCommentClicked(comment: Comment): void {
    if (this.currentUserId === comment.authorUserInfoId) {
      this.editCommentClicked.emit(comment);
    }
  }

  addAction(appointment: Appointment): void {
    this.patientActions$
      .pipe(
        switchMap((patientActions) => {
          const dialogRef = this.dialog.open(
            AddAppointmentActionDialogComponent,
            {
              width: '80rem',
              autoFocus: false,
              data: {
                patientId: appointment.patientId,
                patientActions,
                appointmentId: appointment.id,
              },
              disableClose: true,
            },
          );

          return dialogRef.afterClosed();
        }),
        filter((res) => !!res),
        switchMap((poas: PatientOfferedAction[]) =>
          this.patientOfferedActionService.addToAppointment(poas),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.toastService.success('Storitev je bil dodana!');
        this.refreshAppointments.emit();
        this.storeService.dispatchRefreshTeethOverview();
      });
  }

  deleteAction(
    teeth: TeethEnum,
    patientActionId: number,
    patientOfferedActions: PatientOfferedAction[] | undefined,
  ): void {
    const poaId = patientOfferedActions?.find(
      (poa) => poa?.patientActionId === patientActionId && poa?.teeth === teeth,
    )?.id;
    if (!poaId) {
      return;
    }
    this.patientOfferedActionService
      .removePatientOfferedActionFromAppointment(poaId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.refreshAppointments.emit();
          this.storeService.dispatchRefreshTeethOverview();
          this.toastService.success('Storitev je uklonjena iz posega!');
        },
        error: () => {
          this.toastService.error('Storitev ni uklonjena iz posega!');
        },
      });
  }

  editAction(
    teeth: TeethEnum,
    patientActionId: number,
    patientOfferedActions: PatientOfferedAction[] | undefined,
  ): void {
    const poa = patientOfferedActions?.find(
      (p) => p?.patientActionId === patientActionId && p?.teeth === teeth,
    );
    if (!poa) {
      return;
    }

    const dialogRef = this.dialog.open(EditAppointmentActionDialogComponent, {
      width: '40rem',
      autoFocus: false,
      data: {
        poa,
      },
      disableClose: false,
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((res) => !!res),
        switchMap((res: { comment: string; toothPlane: string }) =>
          this.patientOfferedActionService.updateAppointmentAction({
            ...poa,
            appointmentComment: res.comment,
            appointmentToothPlane: res.toothPlane,
          }),
        ),
      )
      .subscribe(() => {
        this.toastService.success('Akcija izmenjena');
        this.refreshAppointments.emit();
      });
  }

  createReceipt(appointment: Appointment) {
    const patientOfferedActions = appointment?.patientOfferedActions?.filter(
      (poa) =>
        poa.status === PatientOfferedActionStatus.open &&
        poa?.patientAction?.price > 0,
    );
    const patientId = appointment?.patientId;
    if (!patientOfferedActions.length) {
      this.toastService.warning('Nema otvorene / neplacene storitve');
      return;
    }

    forkJoin([
      this.receiptAddresses$,
      this.paymentMethods$,
      this.advanceReceipts$,
      this.patientOfferedActions$,
      this.patientActiveTherapyInvoice$,
    ])
      .pipe(
        switchMap(
          ([
            receiptAddresses,
            paymentMethods,
            advances,
            allPatientOfferedActions,
            patientActiveTherapyInvoice,
          ]) => {
            const actionDiscountMap = new Map<number, number>();
            const priceMap = new Map<number, number>();
            patientActiveTherapyInvoice?.invoiceActions.forEach(
              (action: InvoiceAction) => {
                actionDiscountMap.set(
                  action.patientOfferedAction.patientActionId,
                  action.discount,
                );
                priceMap.set(
                  action?.patientOfferedAction?.patientActionId,
                  action?.currentPrice,
                );
              },
            );
            patientOfferedActions?.forEach((poa) => {
              // It is important that priceMap is first filled with values from ActiveTherapyInvoice
              const price = poa?.appointmentPrice;
              const id = poa?.patientActionId;
              if (price > 0 && id && !priceMap.has(id)) {
                priceMap.set(id, price);
              }
            });
            const dialogRef = this.dialog.open(CreateBillDialogComponent, {
              width: '80rem',
              autoFocus: false,
              disableClose: true,
              data: {
                patientOfferedActions,
                allReceiptAddresses: receiptAddresses,
                patientId,
                entity: 'Račun',
                type: RECEIPT,
                paymentMethods,
                actionDiscountMap,
                priceMap,
                globalDiscount: patientActiveTherapyInvoice?.discount ?? 0,
                advances,
                patientActionDiscountType:
                  patientActiveTherapyInvoice?.patientActionsDiscountType,
                allPatientOfferedActions,
                invoiceClinicLocationId: appointment.clinicLocationId,
              },
            });

            return dialogRef.afterClosed().pipe(
              filter((response) => !!response),
              switchMap(
                (response: {
                  receipt: Receipt;
                  advanceUsed: number;
                  treatmentPlanAdvance: number;
                  visualisationPrepAdvance: number;
                }) =>
                  this.receiptService.addReceipt(
                    response.receipt,
                    response.advanceUsed,
                    response.treatmentPlanAdvance,
                    response.visualisationPrepAdvance,
                  ),
              ),
              take(1),
            );
          },
        ),
      )
      .subscribe(() => {
        this.toastService.success(`Račun je bil dodan!`);
        this.refreshAppointments.emit();
      });
  }
}
