import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatFormField } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AsyncPipe, CommonModule } from '@angular/common';
import { DropdownItem } from '../../shared-components/dropdown-search/dropdown-search.component';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-dropdown-multi-select',
  standalone: true,
  imports: [
    CommonModule,
    MatFormField,
    ReactiveFormsModule,
    AsyncPipe,
    MatAutocompleteModule,
    MatInputModule,
    MatCheckboxModule,
  ],
  templateUrl: './autocomplete-multi-select.component.html',
  styleUrl: './autocomplete-multi-select.component.scss',
})
export class AutocompleteMultiSelectComponent {
  @Input() set options(val: DropdownItem[]) {
    this.optionsTrigger$.next(val);
  }
  @Input() placeholder = 'Izaberi';

  optionsTrigger$ = new BehaviorSubject<DropdownItem[]>([]);
  nameFormControl = new FormControl<string>('');
  selectedItems: DropdownItem[] = [];
  filteredOptions$: Observable<DropdownItem[]>;
  searchTerm = '';

  constructor() {
    this.filteredOptions$ = combineLatest([
      this.nameFormControl.valueChanges.pipe(startWith('')),
      this.optionsTrigger$,
    ]).pipe(
      map(([nameControlVal, options]) =>
        nameControlVal
          ? this._filter(nameControlVal, options)
          : options.slice(),
      ),
    );
  }

  private _filter(value: string, options: DropdownItem[]): DropdownItem[] {
    const filterValue = value.toLowerCase();
    return options.filter((option) =>
      option.name.toLowerCase().includes(filterValue),
    );
  }

  onToggleSelection(option: DropdownItem) {
    const index = this.selectedItems?.findIndex(
      (item) => item.id === option.id,
    );

    if (index >= 0) {
      this.selectedItems?.splice(index, 1);
    } else {
      this.selectedItems?.push(option);
    }
    this.nameFormControl.setValue(this.searchTerm);
  }

  isSelected(option: DropdownItem): boolean {
    return (this.selectedItems || []).some((item) => item.id === option.id);
  }

  onMouseDown() {
    this.searchTerm = this.nameFormControl.value;
  }
}
