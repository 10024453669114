import { Pipe, PipeTransform } from '@angular/core';
import { AdvanceReceipt, ReceiptPagination } from '../../models/receipt.model';
import {
  ADVANCE_RECEIPT,
  RECEIPT,
} from '../../utils/constants/pages.constants';
import { isReceiptOrAdvance } from '../../utils/receipt.utils';

@Pipe({
  name: 'countReceipts',
})
export class CountReceiptsPipe implements PipeTransform {
  transform(
    receipts: ReceiptPagination,
    isPaid?: boolean,
    type?: typeof RECEIPT | typeof ADVANCE_RECEIPT,
    idToMatch?: number,
  ): number {
    return receipts.mixedReceipts
      .filter((r) => !r.isFullyCancelled && !r.originalReceipt)
      .filter((r) => (isPaid === undefined ? true : !!r.paidAt === isPaid))
      .filter((r) => (type ? isReceiptOrAdvance(r) === type : r))
      .filter((r) =>
        idToMatch ? (r as AdvanceReceipt).patientActionId === idToMatch : r,
      ).length;
  }
}
