import { Pipe, PipeTransform } from '@angular/core';
import { PatientOfferedAction } from '../models/patient-offered-action.model';

@Pipe({
  name: 'invoiceSum',
})
export class InvoiceSumPipe implements PipeTransform {
  transform(
    pia: PatientOfferedAction[],
    priceMap: Map<number, number>,
  ): number {
    return pia
      .map((p) => priceMap?.get(p?.patientActionId) ?? p?.patientAction?.price)
      .reduce((totalPrice, itemPrice) => totalPrice + itemPrice, 0);
  }
}
