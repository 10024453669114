import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Comment } from '../../models/comment.model';
import { LocalStorageService } from '../../services/api-services/local-storage.service';

@Component({
  selector: 'app-comment-form-dialog',
  templateUrl: './comment-form-dialog.component.html',
  styleUrls: ['./comment-form-dialog.component.scss'],
})
export class CommentFormDialogComponent implements OnInit {
  public title: string;
  public actionText: string;

  public commentForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<CommentFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { comment: Comment },
    public storageService: LocalStorageService,
    public fb: FormBuilder,
  ) {
    this.title = 'Komentar';
    this.actionText = this.data.comment ? 'Shrani' : 'Dodaj';

    this.commentForm = this.fb.group({
      content: new UntypedFormControl('', Validators.required),
      isSummary: new UntypedFormControl(false, Validators.required),
    });
  }

  ngOnInit(): void {
    if (this.data.comment) {
      this.commentForm.get('content').setValue(this.data.comment.content);
      this.commentForm.get('isSummary').setValue(this.data.comment.isSummary);
    }
  }

  public save(): void {
    if (!this.data.comment) {
      this.data.comment = new Comment();
      this.data.comment.authorUserInfoId = this.storageService.getUserId();
    }

    this.data.comment.content = this.commentForm.get('content').value;
    this.data.comment.isSummary = this.commentForm.get('isSummary').value;

    this.dialogRef.close({ status: 1, comment: this.data.comment });
  }
}
