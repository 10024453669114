import { Pipe, PipeTransform } from '@angular/core';
import { AdvanceReceipt, ReceiptPagination } from '../../models/receipt.model';
import { isReceiptOrAdvance } from '../../utils/receipt.utils';
import {
  ADVANCE_RECEIPT,
  RECEIPT,
} from '../../utils/constants/pages.constants';

@Pipe({
  name: 'sumReceiptPagination',
})
export class SumReceiptPagination implements PipeTransform {
  transform(
    receipts: ReceiptPagination,
    isPaid?: boolean,
    type?: typeof RECEIPT | typeof ADVANCE_RECEIPT,
    idToMatch?: number,
  ): number {
    return receipts.mixedReceipts
      .filter((r) => (isPaid === undefined ? true : !!r.paidAt === isPaid))
      .filter((r) => (type ? isReceiptOrAdvance(r) === type : r))
      .filter((r) =>
        idToMatch ? (r as AdvanceReceipt).patientActionId === idToMatch : r,
      )
      .map((r) =>
        isReceiptOrAdvance(r) === RECEIPT
          ? r.sumToPay
          : (r as AdvanceReceipt).startingBalance,
      )
      .reduce((totalPrice, receiptPrice) => totalPrice + receiptPrice, 0);
  }
}
