import { Pipe, PipeTransform } from '@angular/core';
import { Appointment } from '../models/appointment.model';
import { isDateAfterOrEqualIgnoringTime } from '../utils/calendar-working-hours.utils';
import { AuthorizationService } from '../services/authorization/authorization.service';

@Pipe({
  name: 'canEditAppointment',
})
export class CanEditAppointmentPipe implements PipeTransform {
  transform(appointment: Appointment): boolean {
    return canEditAppointment(appointment, this.authorizationService.isAdmin);
  }

  constructor(private authorizationService: AuthorizationService) {}
}

export function canEditAppointment(
  appointment: Appointment,
  isAdmin: boolean,
): boolean {
  const isValidDate =
    !appointment?.startDateTime ||
    isDateAfterOrEqualIgnoringTime(
      new Date(appointment?.startDateTime),
      new Date(),
    );
  return isValidDate ? true : isAdmin;
}
