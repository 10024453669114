import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClinicLocation } from '../../models/clinic-location.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClinicLocationService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/clinic-location';
  }

  public get(clinicLocationId: number): Observable<ClinicLocation> {
    return this.http.get<ClinicLocation>(this.apiUrl + `/${clinicLocationId}`);
  }

  public add(clinicLocation: ClinicLocation): Observable<number> {
    return this.http.post<number>(this.apiUrl, clinicLocation);
  }

  public update(clinicLocation: ClinicLocation): Observable<ClinicLocation> {
    return this.http.patch<ClinicLocation>(this.apiUrl, clinicLocation);
  }

  public deleteMany(clinicLocationId: number): Observable<any> {
    return this.http.delete<any>(this.apiUrl + `/${clinicLocationId}`);
  }

  public getByClinicId(clinicId?: number): Observable<ClinicLocation[]> {
    return this.http.get<ClinicLocation[]>(
      this.apiUrl + `/by-clinic-id${clinicId ? '/' + clinicId : ''}`,
    );
  }

  public getClinicAddresses(): Observable<ClinicLocation[]> {
    return this.getByClinicId().pipe(
      map((locations: ClinicLocation[]) => {
        return locations.filter(
          (location) => location.content && location.content.trim() !== '',
        );
      }),
    );
  }
}
