import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { PatientSelectDialogComponent } from './patient-select-dialog/patient-select-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextFormDialogComponent } from './text-form-dialog/text-form-dialog.component';
import { CommentFormDialogComponent } from './comment-form-dialog/comment-form-dialog.component';
import { UserInfoTypeToTextPipe } from '../pipes/user-info-type-to-text.pipe';
import { CroppedImageUploadDialogComponent } from './cropped-image-upload-dialog/cropped-image-upload-dialog.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { PatientFormDialogComponent } from './patient-form-dialog/patient-form-dialog.component';
import { MaterialModulesModule } from '../shared-modules/material-modules/material-modules.module';
import { UpdatePasswordFormDialogComponent } from './update-password-form-dialog/update-password-form-dialog.component';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatRippleModule,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { PatientCallFormDialogComponent } from './patient-call-form-dialog/patient-call-form-dialog.component';
import { MinutesToTimePipe } from '../pipes/minutes-to-time.pipe';
import { AppointmentsMultipleFiltersComponent } from './appointments-multiple-filters/appointments-multiple-filters.component';
import { AppointmentCardComponent } from './appointment-card/appointment-card.component';
import { DayToDayNamePipe } from '../pipes/day-to-day-name.pipe';
import { DayToMonthNamePipe } from '../pipes/day-to-month-name.pipe';
import { GoToDateDialogComponent } from './go-to-date-dialog/go-to-date-dialog.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommentCardComponent } from './comment-card/comment-card.component';
import { FirstExaminationCardComponent } from './first-examination-card/first-examination-card.component';
import { DropdownSearchComponent } from './dropdown-search/dropdown-search.component';
import { CalendarTooltipComponent } from '../shared-modules/calendar-pages/calendar-tooltip/calendar-tooltip.component';
import { ChatNamePipe } from '../pipes/chat-name.pipe';
import { ActionTeethSelectionComponent } from './action-teeth-selection/action-teeth-selection.component';
import { TeethNamePipe } from '../pipes/teeth-name.pipe';
import { PatientActionTypePipe } from '../pipes/patient-action-type.pipe';
import { IsActiveTherapyClosablePipe } from '../pipes/is-active-therapy-closable.pipe';
import { CancelDialogComponent } from './cancel-dialog/cancel-dialog.component';
import { MissingTeethToStringPipe } from '../pipes/missing-teeth-to-string.pipe';
import { GroupPatientOfferedActionsPipe } from '../pipes/group-patient-offered-actions.pipe';
import { MapPiaToPoaPipe } from '../pipes/map-pia-to-poa.pipe';
import { CanCreateInvoicePipe } from '../pipes/can-create-invoice.pipe';
import { HeaderFooterTypePipe } from '../pipes/header-footer-type.pipe';
import { InvoiceSumPipe } from '../pipes/invoice-sum.pipe';
import { FormatActionsForInvoicePipe } from '../pipes/format-actions-for-invoice.pipe';
import { CanCreateReceiptPipe } from '../pipes/can-create-receipt.pipe';
import { GroupPatientInvoiceActionsPipe } from '../pipes/group-patient-invoice-actions.pipe';
import { FormatInvoiceActionsForPrintPipe } from '../pipes/format--invoice-actions-for-print.pipe';
import { InvoiceSumWithDiscountPipe } from '../pipes/invoice-sum-with-discount.pipe';
import { InvoiceSumWithRabatPipe } from '../pipes/invoice-sum-with-rabat.pipe';
import { MapBillActionBasicToPoaPipe } from '../pipes/map-bill-action-basic-to-poa.pipe';
import { PriceInversionStornoReceiptsPipe } from '../pipes/price-inversion-storno-receipts.pipe';
import { SubtractAdvancePaidAmountPipe } from '../pipes/subtract-advance-paid-amount.pipe';
import { CanEditAppointmentPipe } from '../pipes/can-edit-appointment.pipe';
import { UserToDropdownItemPipe } from '../pipes/user-to-dropdown-item.pipe';
import { IsTodayPipe } from '../pipes/is-today.pipe';
import { NotificationsComponent } from './notifications/notifications.component';
import { BillActionsSumNumbersPipe } from '../pipes/bill-actions-sum-numbers.pipe';
import { OpenPatientsViewComponent } from './open-patients-view/open-patients-view.component';
import { UserOverviewComponent } from './user-overview/user-overview.component';
import { UserEditDialogComponent } from './user-overview/user-edit-dialog/user-edit-dialog.component';
import { CashRegisterPageComponent } from './cash-register-page/cash-register-page.component';
import { ReceiptSumPipe } from '../pipes/receipt-sum.pipe';
import { CashDepositFormDialogComponent } from './cash-register-page/cash-deposit-form-dialog/cash-deposit-form-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import { NlToBrPipe } from '../pipes/nl-to-br.pipe';
import { PhoneNumberFilterPipe } from '../pipes/phone-number-filter.pipe';
import { CreateAppointmentWithoutTerminDialogComponent } from './create-appointment-without-termin-dialog/create-appointment-without-termin-dialog.component';
import { MapPoasToTeethNamePipe } from '../pipes/map-poas-to-teeth-name.pipe';
import { CommentConfirmationDialogComponent } from './active-therapy-finish-dialog/comment-confirmation-dialog.component';
import { SortPoaOnTeethPipe } from '../pipes/sort-poa-on-teeth.pipe';
import { FdiInvitationFormDialogComponent } from './fdi-invitation-form-dialog/fdi-invitation-form-dialog.component';
import { FindingsDialogComponent } from './findings-dialog/findings-dialog.component';
import { TeethToStringPipe } from '../pipes/teeth-to-string.pipe';
import { PoaAppointmentViewPipe } from '../pipes/poa-appointment-view.pipe';
import { SortTeethPipe } from '../pipes/sort-teeth.pipe';
import { EditAppointmentActionDialogComponent } from './edit-appointment-action-dialog/edit-appointment-action-dialog.component';
import { AddAppointmentActionDialogComponent } from './add-appointment-action-dialog/add-appointment-action-dialog.component';
import { EditActionDoctorPriceDialogComponent } from './edit-action-doctor-price-dialog/edit-action-doctor-price-dialog.component';
import { AnalyticsBoxComponent } from './analytics-box/analytics-box.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { SumReceiptPagination } from '../pipes/analytics/sum-receipt-pagination.pipe';
import { CountReceiptsPipe } from '../pipes/analytics/count-receipts.pipe';
import { SumAppointmentHoursPipe } from '../pipes/analytics/sum-appointment-hours.pipe';
import { CountAppointmentsPipe } from '../pipes/analytics/count-appointments.pipe';
import { SumDoctorCostsPipe } from '../pipes/analytics/sum-doctor-costs.pipe';
import { EditActionTechnicianPriceDialogComponent } from './edit-action-technician-price-dialog/edit-action-technician-price-dialog.component';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { AddTechnicianActionDialogComponent } from './add-technician-action-dialog/add-technician-action-dialog.component';
import { StaffWorkingTimeRecurringEditDialogComponent } from './staff-working-time-reccuring-edit-dialog/staff-working-time-recurring-edit-dialog.component';
import { HasAppointmentUnpaidActionsPipe } from '../pipes/has-appointment-unpaid-actions.pipe';
import { AutocompleteMultiSelectComponent } from '../standalone-components/dropdown-multi-select/autocomplete-multi-select.component';
import { CountTotalPatientActionsPipe } from '../pipes/analytics/count-total-patient-actions';
import { OnlyReceiptHandlingLocationsPipe } from '../pipes/only-receipt-handling-locations.pipe';

@NgModule({
  declarations: [
    ConfirmationDialogComponent,
    StaffWorkingTimeRecurringEditDialogComponent,
    PatientSelectDialogComponent,
    TextFormDialogComponent,
    CommentFormDialogComponent,
    CreateAppointmentWithoutTerminDialogComponent,
    UserInfoTypeToTextPipe,
    MinutesToTimePipe,
    NlToBrPipe,
    MissingTeethToStringPipe,
    GroupPatientOfferedActionsPipe,
    FormatActionsForInvoicePipe,
    MapPiaToPoaPipe,
    HeaderFooterTypePipe,
    CanCreateInvoicePipe,
    UserToDropdownItemPipe,
    CanCreateReceiptPipe,
    GroupPatientInvoiceActionsPipe,
    FormatInvoiceActionsForPrintPipe,
    InvoiceSumWithDiscountPipe,
    InvoiceSumWithRabatPipe,
    CroppedImageUploadDialogComponent,
    PatientFormDialogComponent,
    FdiInvitationFormDialogComponent,
    UpdatePasswordFormDialogComponent,
    PatientCallFormDialogComponent,
    AppointmentsMultipleFiltersComponent,
    AppointmentCardComponent,
    DayToDayNamePipe,
    ChatNamePipe,
    IsActiveTherapyClosablePipe,
    HasAppointmentUnpaidActionsPipe,
    IsTodayPipe,
    CancelDialogComponent,
    CommentConfirmationDialogComponent,
    EditAppointmentActionDialogComponent,
    EditActionDoctorPriceDialogComponent,
    EditActionTechnicianPriceDialogComponent,
    AddAppointmentActionDialogComponent,
    AddTechnicianActionDialogComponent,
    PatientActionTypePipe,
    PhoneNumberFilterPipe,
    TeethNamePipe,
    TeethToStringPipe,
    PoaAppointmentViewPipe,
    MapPoasToTeethNamePipe,
    SortPoaOnTeethPipe,
    SortTeethPipe,
    InvoiceSumPipe,
    DayToMonthNamePipe,
    GoToDateDialogComponent,
    CommentCardComponent,
    FirstExaminationCardComponent,
    ActionTeethSelectionComponent,
    DropdownSearchComponent,
    NotificationsComponent,
    CalendarTooltipComponent,
    MapBillActionBasicToPoaPipe,
    PriceInversionStornoReceiptsPipe,
    SubtractAdvancePaidAmountPipe,
    CanEditAppointmentPipe,
    BillActionsSumNumbersPipe,
    OpenPatientsViewComponent,
    UserOverviewComponent,
    UserEditDialogComponent,
    CashRegisterPageComponent,
    ReceiptSumPipe,
    CashDepositFormDialogComponent,
    FindingsDialogComponent,
    AnalyticsBoxComponent,
    SumReceiptPagination,
    CountReceiptsPipe,
    SumAppointmentHoursPipe,
    CountAppointmentsPipe,
    SumDoctorCostsPipe,
    CustomTableComponent,
    CountTotalPatientActionsPipe,
    OnlyReceiptHandlingLocationsPipe,
  ],
  imports: [
    CommonModule,
    MaterialModulesModule,
    ReactiveFormsModule,
    ImageCropperComponent,
    FontAwesomeModule,
    NgbModule,
    MatCheckboxModule,
    FormsModule,
    RouterModule,
    MatChipsModule,
    MatCardModule,
    MatRippleModule,
    AutocompleteMultiSelectComponent,
  ],
  exports: [
    ConfirmationDialogComponent,
    StaffWorkingTimeRecurringEditDialogComponent,
    PatientSelectDialogComponent,
    TextFormDialogComponent,
    CommentFormDialogComponent,
    CreateAppointmentWithoutTerminDialogComponent,
    UserInfoTypeToTextPipe,
    CroppedImageUploadDialogComponent,
    PatientFormDialogComponent,
    FdiInvitationFormDialogComponent,
    UpdatePasswordFormDialogComponent,
    PatientCallFormDialogComponent,
    MinutesToTimePipe,
    NlToBrPipe,
    MissingTeethToStringPipe,
    GroupPatientOfferedActionsPipe,
    FormatActionsForInvoicePipe,
    MapPiaToPoaPipe,
    HeaderFooterTypePipe,
    CanCreateInvoicePipe,
    UserToDropdownItemPipe,
    CanCreateReceiptPipe,
    GroupPatientInvoiceActionsPipe,
    FormatInvoiceActionsForPrintPipe,
    InvoiceSumWithDiscountPipe,
    InvoiceSumWithRabatPipe,
    DayToDayNamePipe,
    ChatNamePipe,
    IsActiveTherapyClosablePipe,
    HasAppointmentUnpaidActionsPipe,
    IsTodayPipe,
    TeethNamePipe,
    TeethToStringPipe,
    PoaAppointmentViewPipe,
    MapPoasToTeethNamePipe,
    SortPoaOnTeethPipe,
    SortTeethPipe,
    InvoiceSumPipe,
    PatientActionTypePipe,
    PhoneNumberFilterPipe,
    AppointmentsMultipleFiltersComponent,
    AppointmentCardComponent,
    DayToMonthNamePipe,
    GoToDateDialogComponent,
    FirstExaminationCardComponent,
    ActionTeethSelectionComponent,
    DropdownSearchComponent,
    NotificationsComponent,
    CommentCardComponent,
    CalendarTooltipComponent,
    MapBillActionBasicToPoaPipe,
    PriceInversionStornoReceiptsPipe,
    SubtractAdvancePaidAmountPipe,
    MapBillActionBasicToPoaPipe,
    BillActionsSumNumbersPipe,
    AnalyticsBoxComponent,
    SumReceiptPagination,
    CountReceiptsPipe,
    SumAppointmentHoursPipe,
    CountAppointmentsPipe,
    SumDoctorCostsPipe,
    CustomTableComponent,
    CountTotalPatientActionsPipe,
    OnlyReceiptHandlingLocationsPipe,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'sl-SL' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class SharedModule {}
