import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  Observable,
  Subject,
} from 'rxjs';
import { ReceiptService } from '../../services/api-services/receipt.service';
import { filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import {
  AdvanceReceipt,
  Receipt,
  ReceiptPagination,
} from '../../models/receipt.model';
import { FormBuilder } from '@angular/forms';
import { DatePipe, registerLocaleData } from '@angular/common';
import localeSl from '@angular/common/locales/sl';
import { MatDialog } from '@angular/material/dialog';
import { CashDepositFormDialogComponent } from './cash-deposit-form-dialog/cash-deposit-form-dialog.component';
import { LocalStorageService } from '../../services/api-services/local-storage.service';
import {
  CashDeposit,
  CashRegisterInfo,
} from '../../models/cash-register.model';
import { CashDepositService } from '../../services/api-services/cash-deposit.service';
import { CashRegisterPdfService } from '../../services/pdf-services/cash-register-pdf.service';
import { ReceiptHeaderFooterService } from '../../services/api-services/receipt-header-footer.service';
import { CashRegisterService } from '../../services/api-services/cash-register.service';
import { ToastService } from '../../services/other-services/toast.service';
import { isReceiptOrAdvance } from '../../utils/receipt.utils';
import {
  ADVANCE_RECEIPT,
  RECEIPT,
} from '../../utils/constants/pages.constants';
import { AuthorizationService } from '../../services/authorization/authorization.service';
import { ClinicLocationService } from '../../services/api-services/clinic-location.service';
import { ClinicLocation } from '../../models/clinic-location.model';

interface CashRegisterFilters {
  date: Date | string;
  address: ClinicLocation;
}

const DEFAULT_FILTER: CashRegisterFilters = {
  date: undefined,
  address: undefined,
};

export interface CashRegisterReceiptOverview {
  patientName: string;
  patientId?: number;
  amount: number;
  author: string;
  address: string;
  createdAt?: Date;
  identificationNumber: string;
}

interface CombinedReceiptData {
  receipts: CashRegisterReceiptOverview[];
  totalCount: number;
  info: CashRegisterInfo;
}

interface CombinedReturnType {
  receiptPagination: ReceiptPagination;
  cashDeposits: CashDeposit[];
  cashRegisterInfos: CashRegisterInfo[];
}

@Component({
  selector: 'app-cash-register-page',
  templateUrl: './cash-register-page.component.html',
  styleUrls: ['./cash-register-page.component.scss'],
})
export class CashRegisterPageComponent implements OnInit, OnDestroy {
  readonly destroy$ = new Subject<void>();
  readonly displayedColumns: string[] = [
    'številka',
    'patient',
    'amount',
    'creator',
    'address',
  ];
  readonly addresses$ = this.clinicLocationService.getClinicAddresses();

  loadTrigger$: BehaviorSubject<CashRegisterFilters> =
    new BehaviorSubject<CashRegisterFilters>(DEFAULT_FILTER);
  combinedResult$: Observable<CombinedReturnType>;
  combinedReceiptsData$: Observable<CombinedReceiptData>;

  today: string;
  isLoading = true;
  currentUserFullName: string;
  currentUserId: number;
  clinicLocationId: number;
  isCurrentUserAdmin: boolean;
  currentBalance: number;

  formGroup = this.fb.group({
    date: [this.currentDateWithOffset],
    address: [DEFAULT_FILTER.address],
  });

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private clinicLocationService: ClinicLocationService,
    private receiptService: ReceiptService,
    private localStorageService: LocalStorageService,
    private cashDepositService: CashDepositService,
    private cashRegisterService: CashRegisterService,
    private cashRegisterPdfService: CashRegisterPdfService,
    private receiptHeaderFooterService: ReceiptHeaderFooterService,
    private toast: ToastService,
    private authorizationService: AuthorizationService,
  ) {
    registerLocaleData(localeSl);

    this.currentUserFullName = this.localStorageService.getUserFullName();
    this.currentUserId = this.localStorageService.getUserId();
    this.clinicLocationId = this.localStorageService.getClinicLocationId();
    this.isCurrentUserAdmin =
      this.localStorageService.getUserType() === 'Admin';

    this.today = this.formatDateForInput(new Date());

    this.combinedResult$ = this.loadTrigger$.pipe(
      tap(() => (this.isLoading = true)),
      switchMap((filters) =>
        forkJoin({
          receiptPagination: this.loadFilteredReceipts(filters),
          cashDeposits: this.loadCashDeposit(filters),
          cashRegisterInfos: this.loadCashRegisterInfo(filters),
        }),
      ),
      filter(
        ({ receiptPagination, cashDeposits, cashRegisterInfos }) =>
          receiptPagination !== undefined &&
          cashDeposits !== undefined &&
          cashRegisterInfos !== undefined,
      ),
      tap(() => (this.isLoading = false)),
      takeUntil(this.destroy$),
    );
  }

  ngOnInit() {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((values) => {
        const newValue: CashRegisterFilters = {
          date: values.date,
          address: values.address,
        };
        this.loadTrigger$.next(newValue);
      });

    this.combineReceipts();
  }

  private loadFilteredReceipts(
    filters: CashRegisterFilters,
  ): Observable<ReceiptPagination> {
    const date = filters.date ? new Date(filters.date) : undefined;
    return this.receiptService.getReceiptsForDateAndAddress(
      date,
      filters.address?.content,
    );
  }

  private loadCashDeposit(
    filters: CashRegisterFilters,
  ): Observable<CashDeposit[]> {
    const date = filters.date ? new Date(filters.date) : undefined;
    return this.cashDepositService.getCashDepositByDateAndAddress(
      date,
      filters.address?.id,
    );
  }

  private loadCashRegisterInfo(
    filters: CashRegisterFilters,
  ): Observable<CashRegisterInfo[]> {
    const date = filters.date ? new Date(filters.date) : undefined;
    return this.cashRegisterService.getCashRegisterInfoByDateAndAddress(
      date,
      filters.address?.id,
    );
  }

  private combineReceipts() {
    this.combinedReceiptsData$ = this.combinedResult$.pipe(
      map(({ receiptPagination, cashDeposits, cashRegisterInfos }) => {
        const sortedReceipts = receiptPagination.mixedReceipts
          .map((receipt) =>
            this.mapReceiptToCommonModel(receipt, isReceiptOrAdvance(receipt)),
          )
          .reduce((acc, val) => acc.concat(val), []);

        if (cashDeposits && cashDeposits.length > 0) {
          sortedReceipts.push(...this.mapCashDeposits(cashDeposits));
        }

        return {
          receipts: sortedReceipts,
          totalCount: receiptPagination.count,
          info: this.mapCashRegisterInfos(cashRegisterInfos),
        };
      }),
      tap((result) => this.setCurrentBalance(result)),
      takeUntil(this.destroy$),
    );
  }

  private mapReceiptToCommonModel(
    receipt: Receipt | AdvanceReceipt,
    type: typeof RECEIPT | typeof ADVANCE_RECEIPT,
  ): CashRegisterReceiptOverview {
    return {
      patientName: `${receipt.patient.name} ${receipt.patient.lastName}`,
      patientId: receipt.patientId,
      author: `${receipt.creationUserInfo.name} ${receipt.creationUserInfo.lastName}`,
      amount:
        type === RECEIPT
          ? (receipt as Receipt).sumToPay
          : (receipt as AdvanceReceipt).startingBalance,
      address: receipt.clinicLocation.content,
      createdAt: new Date(receipt.createdAt),
      identificationNumber: receipt.identificationNumber,
    };
  }

  private mapCashRegisterInfos(
    cashRegisterInfos: CashRegisterInfo[],
  ): CashRegisterInfo {
    if (!cashRegisterInfos) {
      return null;
    }
    if (cashRegisterInfos.length === 0) {
      return null;
    }
    if (cashRegisterInfos.length === 1) {
      return cashRegisterInfos[0];
    } else {
      return {
        date: cashRegisterInfos[0].date,
        isLocked: cashRegisterInfos.every((cri) => cri.isLocked),
        startingAmount: cashRegisterInfos
          .map((cri) => cri.startingAmount)
          .reduce((total, item) => total + item, 0),
        finalAmount: cashRegisterInfos
          .filter((cri) => !!cri.finalAmount)
          .map((cri) => cri.finalAmount)
          .reduce((total, item) => total + item, 0),
      };
    }
  }

  private mapCashDeposits(
    cashDeposits: CashDeposit[],
  ): CashRegisterReceiptOverview[] {
    const date = new Date(cashDeposits[0].depositedOn); // same date for all
    const formattedDate = date.getDate().toString().padStart(2, '0');
    const formattedMonth = (date.getMonth() + 1).toString().padStart(2, '0');

    return cashDeposits.map((cashDeposit) => {
      const city = cashDeposit.clinicLocation.content
        ?.split(' ')[1]
        .split(',')[0];
      return {
        patientName: `Polog gotovine ${formattedDate}${formattedMonth}${date.getFullYear()} ${city}`,
        author: `${cashDeposit.creationUserInfo.name} ${cashDeposit.creationUserInfo.lastName}`,
        amount: -cashDeposit.amount,
        address: cashDeposit.clinicLocation?.content,
        identificationNumber: '-',
      };
    });
  }

  get currentDateWithOffset() {
    const date = new Date();
    const offset = date.getTimezoneOffset() * 60000;
    const localISOTime = new Date(date.getTime() - offset).toISOString();
    return localISOTime.split('T')[0];
  }

  resetFilters() {
    this.formGroup.get('date').setValue(this.currentDateWithOffset);
    this.formGroup.get('address').setValue(DEFAULT_FILTER.address);
    this.loadTrigger$.next(DEFAULT_FILTER);
  }

  openDialog(): void {
    this.addresses$.pipe(takeUntil(this.destroy$)).subscribe((addresses) => {
      const dialogRef = this.dialog.open(CashDepositFormDialogComponent, {
        width: '20%',
        data: {
          user: this.currentUserFullName,
          address: this.formGroup.get('address').value,
          date: this.formGroup.get('date').value,
          maxAmount: this.currentBalance,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(
          filter((res) => !!res),
          switchMap((result) => {
            const newCashDeposit: CashDeposit = {
              clinicLocationId: addresses.find(
                (a) => a.content === result.address,
              ).id,
              amount: result.amount,
              depositedOn: result.date,
              creationUserInfoId: this.currentUserId,
            };
            return this.cashDepositService.addCashDeposit(newCashDeposit);
          }),
          takeUntil(this.destroy$),
        )
        .subscribe((_) => {
          this.toast.success(`Polog uspješno sačuvan`);
          this.loadTrigger$.next(this.loadTrigger$.value);
        });
    });
  }

  isBankDeposit(receipt: CashRegisterReceiptOverview) {
    return receipt.patientName.includes('Polog gotovine');
  }

  createPdf() {
    const filterDate = this.formGroup.get('date').value;
    const formattedDate = new DatePipe('sl').transform(
      filterDate,
      'dd.MM.yyyy',
    );
    const address = this.formGroup.get('address').value;
    const city = address.content.split(' ')[1].split(',')[0];

    combineLatest([
      this.receiptHeaderFooterService.getReceiptHeader(),
      this.combinedReceiptsData$,
    ])
      .pipe(take(1))
      .subscribe(([header, cashRegisterReceipts]) => {
        this.cashRegisterPdfService
          .createCashRegisterPdf(
            header,
            formattedDate,
            cashRegisterReceipts.receipts,
            cashRegisterReceipts.info,
            city,
          )
          .then();
      });
  }

  cashRegisterLockedToggle(
    combinedReceiptData: CombinedReceiptData,
    shouldLock: boolean,
  ) {
    this.cashRegisterService
      .cashRegisterLockToggle(
        combinedReceiptData.info.id,
        shouldLock,
        this.currentBalance,
      )
      .subscribe((_) => {
        this.toast.success('Blagajna zaključana');
        this.loadTrigger$.next(this.loadTrigger$.value);
      });
  }

  private calculateReceiptSum(
    receipts: CashRegisterReceiptOverview[],
    calculateDeposits: boolean,
  ): number {
    return receipts
      .filter(
        (receipt) =>
          receipt.patientName.includes('Polog') === calculateDeposits,
      )
      .map((receipt) => receipt.amount)
      .reduce((totalPrice, receiptPrice) => totalPrice + receiptPrice, 0);
  }

  formatDateForInput(date: Date): string {
    const d = new Date(date);
    const month = '' + (d.getMonth() + 1);
    const day = '' + d.getDate();
    const year = d.getFullYear();

    return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
  }

  shouldShowButton(button: 'lock' | 'unlock', info: CashRegisterInfo): boolean {
    if ('lock' === button && !this.isUserAuthorized) {
      return false;
    }
    if ('unlock' === button && !this.authorizationService.isAdmin) {
      return false;
    }

    if (!info) {
      return false;
    }

    const isAddressChosen = !!this.formGroup.get('address').value;
    if (!isAddressChosen) {
      return false;
    }

    return button === 'lock' ? !info.isLocked : info.isLocked;
  }

  shouldShowDepositButton(info: CashRegisterInfo) {
    return (
      this.isUserAuthorized &&
      !info?.isLocked &&
      !!this.formGroup.get('address').value
    );
  }

  shouldShowPrintButton(receipts: CashRegisterReceiptOverview[]) {
    return receipts?.length > 0 && !!this.formGroup.get('address').value;
  }

  private get isUserAuthorized() {
    return (
      this.authorizationService.isAdmin ||
      this.authorizationService.isAdministrator ||
      this.authorizationService.isAssistant
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getRouteToNavigate(receipt: CashRegisterReceiptOverview) {
    return `../patients/${receipt.patientId}/${RECEIPT}/${receipt.identificationNumber}`;
  }

  private setCurrentBalance(result: CombinedReceiptData) {
    let receiptSum = this.calculateReceiptSum(result.receipts, false);
    const depositSum = this.calculateReceiptSum(result.receipts, true);

    receiptSum = receiptSum + (result?.info?.startingAmount ?? 0);
    this.currentBalance = receiptSum + depositSum; // deposit sum is already negated
  }
}
