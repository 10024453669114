import { Pipe, PipeTransform } from '@angular/core';
import { DoctorAnalyticsOverview } from '../../models/analytics.model';

@Pipe({
  name: 'countTotalPatientActions',
})
export class CountTotalPatientActionsPipe implements PipeTransform {
  transform(doctorCosts: DoctorAnalyticsOverview[]): number {
    return doctorCosts
      .map((a) => a.countPatientActions)
      .reduce((totalPrice, price) => totalPrice + price, 0);
  }
}
