<div class="card">
  <div class="card-body">
    <div class="title">
      <h5 class="card-title font-weight-bold">Pregled {{index}}. {{appointment.startDateTime ? '' : '(Brez termina)'}}</h5>
      <div class="buttons">
        <button class="btn" *ngIf="editMode && appointment | canEditAppointment" (click)="onEditClicked()" [disabled]="disabled">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </div>
    <div class="info">
      <div class="key-value">
        <div class="key">
          Doktor
        </div>
        <div class="value">
          {{appointment.doctor ? (appointment.doctor.userInfo.name + ' ' + appointment.doctor.userInfo.lastName) : '/'}}
        </div>
      </div>
      <div class="key-value">
        <div class="key">
          Datum
        </div>
        <div class="value">
          {{appointment.startDateTime ? (appointment.startDateTime | date: 'dd.MM.yyyy') + '&emsp;' + (appointment.startDateTime | date: 'HH:mm') : '/'}}
        </div>
      </div>
      <div class="key-value">
        <div class="key">
          Trajanje
        </div>
        <div class="value">
          {{appointment.duration ? (appointment.duration + ' min') : '/'}}
        </div>
      </div>
      <div class="key-value">
        <div class="key">
          Lokacija
        </div>
        <div class="value">
          {{appointment.clinicLocation?.name ?? '/'}}
        </div>
      </div>
      <div class="key-value">
        <div class="key">
          Status
        </div>
        <div class="value">
          {{appointment.appointmentStatus?.name ??  '/'}}
        </div>
      </div>
      <div class="key-value comment-wide">
        <div class="key">
          Opomba
        </div>
        <div class="value">
          {{appointment.remark ? appointment.remark : '/'}}
        </div>
      </div>
    </div>
    <div class="comments">
      <div class="actions-head">Opombe
        <button class="btn add-button" (click)="addComment()" *ngIf="editMode" [disabled]="disabled">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div *ngFor="let appointmentHasComment of appointment?.appointmentHasComment" class="comment">
        <app-comment-card [comment]="appointmentHasComment.comment" (commentClicked)="onCommentClicked($event)" [editMode]="currentUser === appointmentHasComment?.comment?.authorUserInfoId"></app-comment-card>
      </div>
    </div>
  </div>
</div>
