import {
  PatientOfferedAction,
  PatientOfferedActionStatus,
} from '../models/patient-offered-action.model';

export function determineActionStatus(status: string) {
  return status ? `action-${status}` : '';
}

export function determineBillStatus(status: string) {
  return status ? `receipt-${status}` : '';
}

export function determineAppointmentActionsStatus(
  poas: PatientOfferedAction[],
) {
  if (!poas) {
    return 'grey';
  }

  const hasPriceGreaterThanZero = poas?.filter(
    (poa) => poa?.patientAction?.price > 0,
  );
  if (hasPriceGreaterThanZero.length === 0) {
    return 'grey';
  }

  if (
    hasPriceGreaterThanZero.some(
      (poa) => poa.status === PatientOfferedActionStatus.open,
    )
  ) {
    return 'blue';
  } else if (
    hasPriceGreaterThanZero.some(
      (poa) => poa.status === PatientOfferedActionStatus.pending_payment,
    )
  ) {
    return 'orange';
  } else {
    return 'green';
  }
}
