<mat-dialog-content>
  <div>
    <h2>Dodajanje storitve</h2>

    <mat-radio-group class='radio-group' [formControl]='patientActionTypeFormControl'>
      <mat-radio-button [checked]='patientActionTypeFormControl.value === patientActionType.all' [value]='patientActionType.all'>Svi</mat-radio-button>
      <mat-radio-button [checked]='patientActionTypeFormControl.value === patientActionType.top_bottom' [value]='patientActionType.top_bottom'>Zgoraj-Spodaj</mat-radio-button>
      <mat-radio-button [checked]='patientActionTypeFormControl.value === patientActionType.single' [value]='patientActionType.single'>Posamično</mat-radio-button>
    </mat-radio-group>

    <div *ngIf='patientActionTypeFormControl.value === patientActionType.single'>
      <app-action-teeth-selection [selectedTeethArray]="teethArray" (toothClicked)="addOrRemoveTeeth($event)"></app-action-teeth-selection>
      <div class="text-danger" *ngIf="teethArray.length === 0" style='padding: 0 0 0.5rem 0.5rem;'>
        Izberite vsaj en zob!
      </div>
    </div>

    <div *ngIf='patientActionTypeFormControl.value === patientActionType.all'>
      Ova storitve velja za vse zobe!
    </div>

    <div *ngIf='patientActionTypeFormControl.value === patientActionType.top_bottom'>
      <form [formGroup]="topBottomForm">
        <div class="double">
          <label class="form-check-label" for="top">Zgornji zobje</label>
          <input type="checkbox" class="checkbox" id="top" formControlName="top">
        </div>
        <div class="double">
          <label class="form-check-label" for="bottom">Spodnji zobje</label>
          <input type="checkbox" class="checkbox" id="bottom" formControlName="bottom">
        </div>
      </form>
    </div>


    <div>
      <span>Storitev</span>
      <button mat-icon-button (click)='addNewSelectedPatientAction()'>
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <ng-container *ngFor='let selectedPatientAction of selectedPatientActions; let i = index'>
      <div class='patient-action-dropdown'>
        <app-dropdown-search  [selected]='selectedPatientAction | async' (selectedChange)='selectedChange($event, i)' [list]=patientActions [markAsTouched]='dropdownMarkAsTouched'></app-dropdown-search>
        <button *ngIf='selectedPatientActions.length > 1' mat-icon-button (click)='removeSelectedPatientAction(i)'>
          <mat-icon>remove</mat-icon>
        </button>
      </div>
      <div class="text-danger" *ngIf="(selectedPatientAction | async) === null" style='padding: 0 0 0.5rem 0.5rem;'>
        Polje je obvezno!
      </div>
    </ng-container>

    <div id="actionButtons">
      <div></div>
      <div>
        <button class="btn btn-light" [mat-dialog-close]="undefined">Prekliči</button>
      </div>
      <div>
        <button class="btn btn-primary" (click)="save()">Dodaj</button>
      </div>
      <div></div>
    </div>
  </div>
</mat-dialog-content>
