<div class="custom-table-container">

  <table mat-table [dataSource]="dataSource" class="custom-table" matSort>

    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <ng-container *ngIf="column.showHeader !== false">{{ column.header }}</ng-container>
      </th>
      <td mat-cell *matCellDef="let row">
        <span
          [class.clickable]="column.isClickable"
          (click)="column.isClickable ? onCellClick.emit({row, column: column.columnDef}) : null">
          {{ column.cell(row) }}
        </span>
      </td>
    </ng-container>

    <ng-container *ngIf="showHeader">
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    </ng-container>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
