import { UserInfo } from './user-info.model';

export class Comment {
  public id: number;
  public content: string;
  public authorUserInfoId: number;
  public authorUserInfo: UserInfo;
  public modifiedDateTime: Date;
  public isSummary: boolean;
  public patientId?: number;
}
