import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../../models/user.model';
import { Administrator } from '../../models/administrator.model';

@Injectable({
  providedIn: 'root',
})
export class AdministratorService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient) {
    this.apiUrl = environment.apiUrl + '/administrator';
  }

  public add(administrator: User): Observable<number> {
    return this.http.post<number>(this.apiUrl, administrator);
  }

  public getMyClinicId(): Observable<number> {
    return this.http.get<number>(this.apiUrl + `/my-clinic-id`);
  }

  public update(administrator: User): Observable<Administrator> {
    return this.http.patch<Administrator>(this.apiUrl, administrator);
  }

  public delete(administratorId: number): Observable<any> {
    return this.http.delete<any>(this.apiUrl + `/${administratorId}`);
  }

  public getByClinicId(clinicId: number): Observable<Administrator[]> {
    return this.http.get<Administrator[]>(
      this.apiUrl + `/by-clinic-id/${clinicId}`,
    );
  }
}
